<template>
  <b-modal
    id="modal-product-report"
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="md"
    title="Unduh Kartu Stok"
    class="custom__modal"
  >
    <b-form-group>
      <label class="mb-25" for="v-satuan">Nama Produk</label>
      <v-select
        v-model="formPayload.product_uuid"
        placeholder="Cari Nama Produk"
        label="name"
        :options="products"
        @search="onSearchProduct"
      />
      <!-- <small
        v-if="messages.uom_uuid && Array.isArray(messages.uom_uuid) && messages.uom_uuid.length > 0"
        class="text-error fw-bold-500 size12"
      >{{ messages.uom_uuid.join(', ') }}</small> -->
    </b-form-group>
    <div class="d-flex">
      <b-button class="mx-auto fw-bold-800 size18" size="lg" @click="exportReport('product_stock')">
        <b-spinner v-if="isLoading" small />
        Unduh
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BModal, BFormGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  props: {
    exportReport: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    BSpinner,
    BModal,
    vSelect,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      products: [],
      formPayload: {
        product_uuid: '',
      },
    }
  },
  watch: {
    formPayload: {
      handler(val) {
        this.$emit('productUuid', val.product_uuid.uuid)
      },
      deep: true,
    },
  },
  created() {
    this.getProducts()
  },
  methods: {
    async getProductDetail(id) {
      await this.$store.dispatch('cashier/getData', {
        uuid: id || '',
        params: '',
      })
        .then(result => {
          this.products = result.data.data.name
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    onSearchProduct(search, loading) {
      if (search.length) {
        loading(true)
        this.searchProduct(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.products = []
          this.getProducts()
          loading(false)
        }, 500)
      }
    },
    searchProduct: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('cashier/getData', {
        uuid: '',
        params: {
          search: escape(search),
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.products = response.data.data.data
          loading(false)
        })
    }, 300),
    async getProducts() {
      await this.$store.dispatch('cashier/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        // eslint-disable-next-line no-plusplus
        this.products = result.data.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
