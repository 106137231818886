<template>
  <b-modal
    :id="idModal"
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    no-close-on-backdrop
    size="md"
    title="Unduh Laporan"
    class="custom__modal"
  >
    <b-row class="p-2">
      <b-col cols="12" class="mb-2 custom__form--input">
        <b-form-group>
          <label for="v-jenis-pajak">Jenis Laporan</label>
          <b-form-select
            id="v-jenis-pajak"
            v-model="formPayload.report_type"
            :options="filteredOptions"
            class="text-capitalize"
          />
        </b-form-group>
      </b-col>
      <!-- Start Date & End Date -->
      <b-col
        cols="12"
        class="mb-2 custom__form--input"
        v-if="formPayload.report_type"
      >
        <div class="w-100" v-if="formPayload.report_type === 'actual_stock'">
          <label
            for="second-date-datepicker"
            class="text-dark size12 fw-bold-600"
            >Tanggal Akhir<span class="text-danger">*</span></label
          >
          <b-form-datepicker
            id="second-date-datepicker"
            v-model="formPayload.end_date"
            class="custom__input size12 text-dark fw-bold-600 bg-light bg-line"
            :max="max"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="DD/MM/YYYY"
            locale="id"
          />
        </div>
        <div class="d-flex" v-else>
          <div class="w-50 pr-1">
            <label
              for="first-date-datepicker"
              class="text-dark size12 fw-bold-600"
              >Tanggal Awal<span class="text-danger">*</span></label
            >
            <b-form-datepicker
              id="first-date-datepicker"
              v-model="formPayload.start_date"
              class="custom__input size12 text-dark fw-bold-600 bg-light bg-line"
              :max="max"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              placeholder="DD/MM/YYYY"
              locale="id"
            />
          </div>
          <div class="w-50">
            <label
              for="second-date-datepicker"
              class="text-dark size12 fw-bold-600"
              >Tanggal Akhir<span class="text-danger">*</span></label
            >
            <b-form-datepicker
              id="second-date-datepicker"
              v-model="formPayload.end_date"
              class="custom__input size12 text-dark fw-bold-600 bg-light bg-line"
              :max="max"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              placeholder="DD/MM/YYYY"
              locale="id"
            />
          </div>
        </div>
      </b-col>
      <!-- Cabang -->
      <b-col
        v-if="
          formPayload.report_type &&
          formPayload.report_type !== 'actual_stock' &&
          formPayload.report_type !== 'transfer_stock'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-cabang">Cabang</label>
          <v-select
            id="v-pemasok"
            v-model="formPayload.branch_uuid"
            :options="optionsCabang"
            label="title"
            placeholder="Pilih Cabang"
            class="custom__input"
            @search="onSearchCabang"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="
          formPayload.report_type && formPayload.report_type == 'transfer_stock'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-cabang">Cabang</label>
          <v-select
            id="v-pemasok"
            v-model="formPayload.branch_origin_uuid"
            :options="optionsCabang"
            label="title"
            placeholder="Pilih Cabang"
            class="custom__input"
            @search="onSearchCabang"
          />
        </b-form-group>
      </b-col>
      <!-- Jenis Pajak -->
      <b-col
        v-if="
          formPayload.report_type &&
          formPayload.report_type !== 'utang' &&
          formPayload.report_type !== 'supplier_payment' &&
          formPayload.report_type !== 'other_payment' &&
          formPayload.report_type !== 'actual_stock' &&
          formPayload.report_type !== 'piutang' &&
          formPayload.report_type !== 'piutang_payment' &&
          formPayload.report_type !== 'product_stock' &&
          formPayload.report_type !== 'transfer_stock' &&
          formPayload.report_type !== 'gross_profit' &&
          formPayload.report_type !== 'stock_opname'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-jenis-pajak">Jenis Pajak</label>
          <b-form-select
            id="v-jenis-pajak"
            v-model="formPayload.non_tax"
            :options="[
              { value: '', text: 'Pilih Jenis Pajak' },
              { value: '0', text: 'Pajak' },
              { value: '1', text: 'Non-Pajak' },
              { value: 'semua', text: 'Semua' },
            ]"
          />
        </b-form-group>
      </b-col>
      <!-- Konsumen -->
      <b-col
        v-if="
          (formPayload.report_type && formPayload.report_type == 'order') ||
          formPayload.report_type == 'piutang' ||
          formPayload.report_type == 'piutang_payment'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-konsumen">Konsumen</label>
          <v-select
            id="v-konsumen"
            v-model="formPayload.customer_uuid"
            placeholder="Pilih Konsumen"
            class="custom__input"
            :options="optionsKonsumen"
            label="title"
            @search="onSearchKonsumen"
          />
        </b-form-group>
      </b-col>
      <!-- Supplier -->
      <b-col
        v-if="
          (formPayload.report_type &&
            formPayload.report_type == 'supplier_payment') ||
          formPayload.report_type == 'utang' ||
          formPayload.report_type == 'incoming_stock_supplier' ||
          formPayload.report_type == 'incoming_stock_warehouse'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-pemasok">Pemasok</label>
          <v-select
            id="v-pemasok"
            v-model="formPayload.supplier_uuid"
            placeholder="Pilih Pemasok"
            class="custom__input"
            :options="optionsPemasok"
            label="title"
            @search="onSearchPemasok"
          />
        </b-form-group>
      </b-col>
      <!-- Jatuh Tempo -->
      <b-col
        v-if="
          (formPayload.report_type && formPayload.report_type == 'utang') ||
          formPayload.report_type == 'piutang' ||
          formPayload.report_type == 'piutang_payment'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="due-date-datepicker" style="text-transform: capitalize">
            {{
              formPayload.report_type === "piutang_payment"
                ? "Pembayaran Piutang"
                : formPayload.report_type
            }}
            Jatuh Tempo
          </label>
          <b-form-datepicker
            id="due-date-datepicker"
            v-model="formPayload.due_date"
            class="custom__input size12 text-dark fw-bold-600 bg-light bg-line"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="DD/MM/YYYY"
            locale="id"
          />
        </b-form-group>
      </b-col>
      <!-- Lokasi SKU -->
      <b-col
        v-if="
          (formPayload.report_type &&
            formPayload.report_type === 'actual_stock') ||
          formPayload.report_type === 'stock_opname'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-lokasi-sku">Lokasi SKU</label>
          <v-select
            id="v-lokasi-sku"
            v-model="formPayload.warehouse_uuid"
            placeholder="Pilih Lokasi SKU"
            class="custom__input"
            :options="optionsLokasi"
            label="title"
            @search="onSearchLokasi"
          />
        </b-form-group>
      </b-col>
      <!-- Kasir -->
      <b-col
        v-if="formPayload.report_type && formPayload.report_type == 'cashier'"
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-kasir">Kasir</label>
          <v-select
            id="v-kasir"
            v-model="formPayload.operator_uuid"
            placeholder="Pilih Kasir"
            class="custom__input"
            :options="optionsKasir"
            label="title"
            @search="onSearchKasir"
          />
        </b-form-group>
      </b-col>
      <!-- Produk -->
      <b-col
        v-if="
          formPayload.report_type && formPayload.report_type == 'product_stock'
        "
        cols="12"
        class="mb-2 custom__form--input"
      >
        <b-form-group>
          <label for="v-produk"
            >Nama SKU<span class="text-danger">*</span></label
          >
          <v-select
            id="v-produk"
            v-model="formPayload.product_uuid"
            placeholder="Pilih Nama SKU"
            class="custom__input"
            :options="optionsProduk"
            label="title"
            @search="onSearchProduk"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="button__group">
      <b-button
        id="button--cancel__transaction"
        class="button__cancel"
        @click="$bvModal.hide(idModal)"
      >
        Batal
      </b-button>
      <b-button
        id="button--save__transaction"
        class="button__save"
        :disabled="
          (formPayload.report_type === 'product_stock' &&
            !formPayload.product_uuid) ||
          !formPayload.end_date
        "
        @click="exportReport(type)"
      >
        <b-spinner
          v-if="isLoadingExport"
          variant="primary"
          label="Loading..."
        />
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  BFormInput,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  components: {
    BFormGroup,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BFormSelect,
  },
  props: {
    idModal: {
      type: String,
    },
    exportReport: {
      type: Function,
    },
    optionsProduk: {
      type: Array,
    },
    getProduk: {
      type: Function,
    },
    isLoadingExport: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    const minDate = new Date(today);
    return {
      type: null,
      formPayload: {},
      start_date: "",
      end_date: "",
      // optionsJenisLaporan: [
      //   {
      //     value: null,
      //     text: "Pilih Jenis Laporan",
      //   },
      //   {
      //     value: "supplier_payment",
      //     text: "Laporan Pembayaran Ke Pemasok",
      //   },
      //   {
      //     value: "other_payment",
      //     text: "Laporan Pembayaran Lainnya",
      //   },
      //   {
      //     value: "piutang_payment",
      //     text: "Laporan Pembayaran Piutang",
      //   },
      //   {
      //     value: "utang",
      //     text: "Laporan Utang",
      //   },
      //   {
      //     value: "piutang",
      //     text: "Laporan Piutang",
      //   },
      //   {
      //     value: "incoming_stock_supplier",
      //     text: "Laporan Pembelian Stok Pemasok",
      //   },
      //   {
      //     value: "incoming_stock_warehouse",
      //     text: "Laporan Pembelian Stok Gudang",
      //   },
      //   {
      //     value: "cashier",
      //     text: "Laporan Kasir",
      //   },
      //   {
      //     value: "actual_stock",
      //     text: "Laporan Stok Aktual",
      //   },
      //   {
      //     value: "order",
      //     text: "Laporan Penjualan",
      //   },
      //   {
      //     value: "gross_profit",
      //     text: "Laporan Laba Kotor",
      //   },
      //   {
      //     value: "transfer_stock",
      //     text: "Laporan Transfer Stok",
      //   },
      //   {
      //     value: "product_stock",
      //     text: "Kartu Stok",
      //   },
      // ],
      optionsCabang: [],
      optionsKonsumen: [],
      optionsPemasok: [],
      optionsLokasi: [],
      optionsKasir: [],
      // optionsProduk: [],
      max: maxDate,
      min: minDate,
      userRole: JSON.parse(localStorage.getItem("userRole")),
    };
  },
  watch: {
    formPayload: {
      handler(value) {
        this.$emit("filterExport", value);
      },
      deep: true,
    },
    "formPayload.report_type": function (value) {
      if (value) {
        this.formPayload = {
          report_type: value,
        };
      }
    },
    "formPayload.start_date": function (startDate) {
      if (startDate) {
        const minDate = new Date(startDate);
        this.min = minDate;
      }
    },
  },
  computed: {
    filteredOptions() {
      const selections = [];

      const reportTypePermissions = {
        supplier_payment: "laporan pembayaran ke pemasok",
        other_payment: "laporan pembayaran lainnya",
        piutang_payment: "laporan pembayaran piutang",
        utang: "laporan utang",
        piutang: "laporan piutang",
        incoming_stock_warehouse: "laporan pembelian stok gudang",
        incoming_stock_supplier: "laporan pembelian stok pemasok",
        cashier: "laporan kasir",
        actual_stock: "laporan stok aktual",
        order: "laporan penjualan",
        gross_profit: "laporan laba kotor",
        transfer_stock: "laporan transfer stok",
        stock_opname: "laporan stok opname",
        product_stock: "kartu stok",
      };

      for (const [reportType, permission] of Object.entries(
        reportTypePermissions
      )) {
        if (checkPermission(permission)) {
          selections.push({ value: reportType, text: permission });
        }
      }
      return selections;
    },
  },
  mounted() {
    this.getCabang();
    this.getCustomer();
    this.getPemasok();
    this.getLokasi();
    this.getKasir();
    // this.getProduk()
  },
  methods: {
    // SKU
    // getProduk() {
    //   this.$store.dispatch('cashier/getData', { uuid: '', params: '' })
    //     .then(result => {
    //       this.optionsProduk = result.data.data.data.map(e => {
    //         return {
    //           label: e.uuid,
    //           title: e.name
    //         }
    //       })
    //     }).catch(err => {
    //       console.log(err)
    //     })
    // },
    onSearchProduk(search, loading) {
      if (search.length) {
        loading(true);
        this.searchProduk(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsProduk = [];
          this.getProduk();
          loading(false);
        }, 500);
      }
    },
    searchProduk: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsProduk = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),

    // Kasir
    getKasir() {
      this.$store
        .dispatch("users/getData", { uuid: "", params: "" })
        .then((result) => {
          this.optionsKasir = result.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearchKasir(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKasir(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsKasir = [];
          this.getKasir();
          loading(false);
        }, 500);
      }
    },
    searchKasir: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("users/getData", {
          uuid: "",
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsKasir = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),

    // Lokasi SKU
    getLokasi() {
      this.$store
        .dispatch("warehouse/getData", { params: "" })
        .then((result) => {
          this.optionsLokasi = result.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsLokasi = [];
          this.getLokasi();
          loading(false);
        }, 500);
      }
    },
    searchLokasi: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("warehouse/getData", {
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsLokasi = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),

    // PEMASOK
    getPemasok() {
      this.$store
        .dispatch("supplier/getData", { params: "" })
        .then((result) => {
          this.optionsPemasok = result.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearchPemasok(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPemasok(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsPemasok = [];
          this.getPemasok();
          loading(false);
        }, 500);
      }
    },
    searchPemasok: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("supplier/getData", {
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsPemasok = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),

    // CUSTOMER
    getCustomer() {
      this.$store
        .dispatch("customer/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.optionsKonsumen = result.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    onSearchKonsumen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKonsumen(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsKonsumen = [];
          this.getKonsumen();
          loading(false);
        }, 500);
      }
    },
    searchKonsumen: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("customer/getData", {
          uuid: "",
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsKonsumen = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),

    // CABANG
    async getCabang() {
      try {
        let response = await this.$store.dispatch("cabang/getData", {
          uuid: "",
          params: "",
        });
        this.optionsCabang = response.data.data.data.map((e) => {
          return {
            label: e.uuid,
            title: e.name,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    onSearchCabang(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCabang(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsCabang = [];
          this.getCabang();
          loading(false);
        }, 500);
      }
    },
    searchCabang: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("cabang/getData", {
          uuid: "",
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsCabang = response.data.data.data.map((e) => {
            return {
              label: e.uuid,
              title: e.name,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
    }, 300),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.modal-content {
  border-radius: 16px;
  .modal-header {
    background-color: #fff !important;
    border-bottom: 1px solid #eee !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .modal-title {
      margin-left: auto;
    }
  }
  .modal-body {
    padding: 0;
  }
}

.button__group {
  display: flex;
  width: 100%;

  .button__cancel {
    background-color: #ffffff !important;
    border-color: #fff !important;
    color: $dark !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 0px 16px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }

  .button__save {
    background-color: #45b6ab !important;
    border-color: #45b6ab !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }
}
</style>
